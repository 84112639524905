.container {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.map {
    cursor: none;
    position: absolute;
}

.export-container {
    overflow: scroll;
    background: white;
    border-radius: 30px;
    margin: 50px;
    width: 15vw;
    position: absolute;
    padding: 20px;
    right: 0;
    top: 0;
    bottom: 0;
}

.floor-button {
    color: #3A51A3;
    font-size: 24px;
    font-weight: bold;
    width: 40px;
    height: 40px;
    border-radius: 30px;
    background: white;
    border-width: 0;
    cursor: pointer;
}

.floor-container {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50px;
    left: 50px;
}

.floor-text {
    color: white;
    font-size: 16px;
    font-weight: bold;
    margin: 10px;
}

.location-container {
    align-items: center;
    justify-content: center;
    display: flex;
    position: fixed;
    bottom: 0;
}

.location-button {
    padding-left: 10px;
    padding-right: 10px;
    margin: 25px 10px;
    color: #3A51A3;
    font-size: 14px;
    font-weight: bold;
    border-radius: 30px;
    background: white;
    border-width: 0;
    cursor: pointer;
}